import React from 'react'

import Loader from 'components/shared/loader'

// Just a loading page to retrieve the authentication code from the URL
const AuthCalendly = () => {
  return <Loader />
}

export default AuthCalendly
